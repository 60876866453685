import React from "react"
import { graphql, Link, PageProps } from "gatsby"
import { StyledMain, StyledH1, StyledSection, StyledH2 } from "../styles"
import { FourOhFourQuery } from "../../graphql-types"

const NotFoundPage = ({ data: { contentfulPage } }: PageProps<FourOhFourQuery>) => {
  if (!contentfulPage) return null

  const { tagline, title } = contentfulPage;
  return <>
    <StyledMain>
      <StyledH1 children={title} />
    </StyledMain>
    <StyledSection>
      <StyledH2>
        <Link to="/">{tagline}</Link>
      </StyledH2>
    </StyledSection>
  </>
}

export default NotFoundPage

export const pageQuery = graphql`
  query fourOhFour {
    contentfulPage(contentful_id: {eq: "2jdUlCoB3nJWzIAOXK8mbr"}) {
      title
      tagline
    }
  }
`